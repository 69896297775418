import React, { useEffect } from 'react';

import { CircularProgress } from '@mui/material';
import { useSelector } from 'react-redux';

//? Redux
import { RootState } from '../../redux/store';
import { ANALYTICS_EVENTS } from '../../shared/constants/events';
import ROUTES from '../../shared/constants/routes';
import useCleverTapEvent from '../../hooks/useClevertapEvent';
import FiscalFlowTransactions from '../fiscalFlowTransactions';
import UnlinkedUser from '../home/organisms/UnlinkedUser';
import { SATLinkStatus } from '../../@types/SATLinking';
import useService from '../../hooks/useService';

const ServiceStatus: React.FC = () => {
	const logClevertapEvent = useCleverTapEvent();
	const { getSatLinkedAccount, isLoading } = useService();

	const { status } = useSelector((state: RootState) => state.satLinkStatus);
	const userProfile = useSelector((state: RootState) => state.profile.user);

	useEffect(() => {
		if (userProfile) {
			getSatLinkedAccount(userProfile?.id!, userProfile?.cellphone!);
		}
	}, [userProfile]);

	useEffect(() => {
		logClevertapEvent(ANALYTICS_EVENTS.PAGE_VIEWED, {
			name: ROUTES.SERVICE_STATUS,
		});
	}, []);

	if (isLoading.satLinkStatus) {
		return (
			<div className='flex items-center justify-center mt-5'>
				<CircularProgress size={25} />
			</div>
		);
	}

	if (status.status !== SATLinkStatus.ACTIVE) {
		return (
			<div className='mt-5'>
				<UnlinkedUser page='service-status' />
			</div>
		);
	}

	return (
		<>
			<FiscalFlowTransactions />
		</>
	);
};

export default React.memo(ServiceStatus);
