import { ArrowRight } from '@phosphor-icons/react';
import { useMediaQuery } from '@mui/material';

import Button from '../../atoms/Button';
import HomeCard from '../../molecules/HomeCard';
import CardDetailFFT from '../CardDetailFFT';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RootState } from '../../../redux/store';

const DeclarationStatusFFT = () => {
	const serviceStatusElement = useSelector(
		(state: RootState) => state.profile.serviceStatus,
	);
	const isMobile = useMediaQuery('(max-width: 768px)');
	const navigate = useNavigate();

	const handleGoToAllDeclarations = () => {
		navigate('/fft');
	};

	return (
		<HomeCard
			title='Estado de mis servicios'
			showButton={false}
			href={'/declarations'}
			headerButton={
				<>
					{serviceStatusElement?.has_more_ffts && (
						<Button
							onClick={() => {
								handleGoToAllDeclarations();
							}}
							type='button'
							label={isMobile ? 'Ver todos' : 'Ver todos mis servicios'}
							variant='text'
							icon={<ArrowRight size={20} />}
							labelColor='primary'
						/>
					)}
				</>
			}
		>
			<CardDetailFFT declaration={serviceStatusElement!} />
		</HomeCard>
	);
};

export default DeclarationStatusFFT;
