import React, { useCallback, useEffect, useMemo, useState } from 'react';
import Text from '../../../../../components/atoms/Text';
import { CircularProgress, Radio, SelectChangeEvent, Tooltip } from '@mui/material';
import HeruStripe from '../../../HeruStripe';
import { Order } from '../../../../../@types/Order.type';
import { SealCheck, CreditCard, Info } from '@phosphor-icons/react';
import { Card, PAYMENT_METHOD } from '../../../../../@types/Checkout.type';
import { getPaymentCards } from '../../../../../services/payments.service';
import { capitalizeFirstLetter } from '../../../../../shared/utils/general.util';
import { IPaymentInfo } from '../../../../../@types/Payments.types';
import PaymentMethodItem from '../../molecules/PaymentMethodItem';
import OxxoPay from '../../../../../assets/img/svg/oxxopay.svg';
import Spei from '../../../../../assets/img/svg/spei.svg';
import Paypal from '../../../../../assets/img/svg/logo-Paypal.svg';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../redux/store';
import { getBrandIcon } from '../../../../../shared/utils/payments.util';

const paymentsOptions = [
	PAYMENT_METHOD.CARD,
	PAYMENT_METHOD.CASH,
	PAYMENT_METHOD.SPEI,
	PAYMENT_METHOD.PAYPAL,
];

interface SelectPaymentMethodProps {
	order: Order;
	selectPaymentMethod: (
		paymentMethod: PAYMENT_METHOD,
		paymentInfo?: IPaymentInfo,
	) => void;
	scrollToBottom: () => void;
	confirmPayment: boolean;
	setConfrimPayment: (value: boolean) => void;
	customerStripeId: string;
}
const SelectPaymentMethod: React.FC<SelectPaymentMethodProps> = ({
	order,
	selectPaymentMethod,
	scrollToBottom,
	confirmPayment,
	setConfrimPayment,
	customerStripeId,
}) => {
	const [category, setCategory] = useState<PAYMENT_METHOD | string>(
		PAYMENT_METHOD.CARD,
	);
	const [allPaymentsCards, setAllPaymentsCards] = useState<Card[]>([]);
	const [isLoadingCards, setIsLoadingCards] = useState(false);
	const [cardSelected, setCardSelected] = useState<Card>();
	const [paymentCategories, setPaymentCategories] = useState(paymentsOptions);
	const { tokenSession } = useSelector((state: RootState) => state.auth);

	const handleChange = (event: SelectChangeEvent) => {
		if (
			event.target.value === PAYMENT_METHOD.CARD ||
			event.target.value === PAYMENT_METHOD.CASH ||
			event.target.value === PAYMENT_METHOD.SPEI ||
			event.target.value === PAYMENT_METHOD.PAYPAL
		) {
			setCategory(event.target.value as PAYMENT_METHOD);
			selectPaymentMethod(event.target.value as PAYMENT_METHOD);
		} else {
			setCategory(event.target.value as string);
			const payment = allPaymentsCards.find(
				(card) => card.id === event.target.value,
			);
			setCardSelected(payment);
			const platformPayment = {
				platform: payment?.customer?.platform?.platform_name ?? 'stripe',
				token: payment?.id!,
			};
			selectPaymentMethod(PAYMENT_METHOD.CARD, platformPayment);
		}
	};

	const categoryName = useMemo(
		() => (_category: PAYMENT_METHOD) => {
			switch (_category) {
				case PAYMENT_METHOD.CARD:
					return 'Agregar tarjeta de crédito o débito';
				case PAYMENT_METHOD.CASH:
					return 'Efectivo';
				case PAYMENT_METHOD.SPEI:
					return 'Transferencia';
				case PAYMENT_METHOD.PAYPAL:
					return 'PayPal';
				default:
					return 'N/A';
			}
		},
		[],
	);

	const categoryImage = useMemo(
		() => (_category: PAYMENT_METHOD) => {
			switch (_category) {
				case PAYMENT_METHOD.CARD:
					return '';
				case PAYMENT_METHOD.CASH:
					return OxxoPay;
				case PAYMENT_METHOD.SPEI:
					return Spei;
				case PAYMENT_METHOD.PAYPAL:
					return Paypal;
				default:
					return '';
			}
		},
		[],
	);

	const categoryDescription = useMemo(
		() => (_category: PAYMENT_METHOD) => {
			switch (_category) {
				case PAYMENT_METHOD.CARD:
					return 'Paga fácil con cualquier tarjeta bancaria';
				case PAYMENT_METHOD.CASH:
					return 'Completa tu pago en cualquier sucursal';
				case PAYMENT_METHOD.SPEI:
					return 'Completa tu pago en la app de tu banco';
				case PAYMENT_METHOD.PAYPAL:
					return 'Paga con tu cuenta de PayPal';
				default:
					return '';
			}
		},
		[],
	);

	const getCardList = useCallback(async () => {
		try {
			setIsLoadingCards(true);
			const resp: Card[] = await getPaymentCards(tokenSession);
			setAllPaymentsCards(resp);
			setIsLoadingCards(false);
			if (resp?.length > 0) {
				const lastCard = resp[0];
				setCategory(lastCard?.id);
				setCardSelected(lastCard);
				const platformPayment = {
					platform: lastCard?.customer?.platform?.platform_name ?? 'stripe',
					token: lastCard?.id,
				};
				selectPaymentMethod(PAYMENT_METHOD.CARD, platformPayment);
			}
		} catch (error) {
			setIsLoadingCards(false);
		}
	}, []);

	// const deletePaymentCard = useCallback(async () => {
	// 	//TODO: delete payment card
	// }, []);

	useEffect(() => {
		getCardList();
	}, []);

	useEffect(() => {
		if (!order) return;
		if (order?.items?.find((item) => item.purchase_type === 'subscription')) {
			setPaymentCategories(
				paymentsOptions.filter((item) => item === PAYMENT_METHOD.CARD),
			);
		} else {
			setPaymentCategories(paymentsOptions);
		}
	}, [order]);

	return (
		<div className='mb-20 w-full p-6 bg-white rounded-lg shadow flex-col justify-start items-start gap-[15px] inline-flex'>
			<Text
				size='xl'
				weight='bold'
				sx='hidden lg:block'
				color='medium'
			>
				Métodos de pago
			</Text>
			<Text
				size='l'
				weight='bold'
				sx='lg:hidden block'
				color='medium'
			>
				Métodos de pago
			</Text>
			<div className='w-full h-[0px] border border-neutral-200'></div>
			{isLoadingCards ? (
				<div className='flex flex-row items-center justify-center m-2 w-full'>
					<CircularProgress />
				</div>
			) : (
				<>
					{order?.total === 0 ? (
						<div className='w-full h-auto p-6 bg-h-blue-20 rounded-lg shadow flex-col justify-center items-center gap-[15px] inline-flex'>
							<SealCheck
								size={32}
								weight='fill'
								color='var(--h-blue-50)'
							/>
							<Text
								size='xl'
								weight='bold'
							>
								¡Felicidades!
							</Text>
							<Text>
								{`El total de tu carrito es de $${order?.total}, Haz clic en pagar para disfrutar de tu compra.`}
							</Text>
						</div>
					) : (
						<>
							<div className='w-full'>
								<div className='flex flex-col justify-between border-b'>
									<Text
										size='body-2'
										sx='mb-4'
										color='medium'
									>
										Tus tarjetas
									</Text>
									{allPaymentsCards.map((card) => (
										<div key={`cards-${card.id}`}>
											<div className='flex flex-row mb-2 items-center gap-y-4'>
												<Radio
													checked={category === card.id}
													onChange={handleChange}
													value={card.id}
													name='radio-buttons-payments'
													inputProps={{ 'aria-label': 'A' }}
												/>

												<CardInfo card={card} />
											</div>
											{!(category in PAYMENT_METHOD) &&
												cardSelected?.id === card.id && (
													<div className='w-full flex flex-col mt-2 mb-6 border px-4 py-[18px] rounded-lg'>
														<HeruStripe
															scrollToBottom={scrollToBottom}
															order={order}
															onPaymentMethodSuccess={getCardList}
															paymentMethodId={cardSelected?.id}
															confirmPayment={confirmPayment}
															showPaymentElement={false}
															setConfrimPayment={setConfrimPayment}
															customerStripeId={customerStripeId}
														/>
														<div className='flex flex-col md:flex-row justify-between items-center gap-x-10'>
															<div className='w-full md:w-1/2'>
																<Text
																	size='m'
																	color='medium'
																>
																	Número de tarjeta
																</Text>
																<div className='flex flex-row justify-between border px-4 py-0.5 border-h-gray-20 rounded-[4px] mt-2'>
																	<Text
																		size='m'
																		color='medium'
																	>
																		**** {cardSelected?.details?.last_four}
																	</Text>
																	<img
																		src={getBrandIcon(
																			cardSelected?.details?.brand,
																		)}
																		alt='brand'
																		className='w-[32px] h-[32px] ml-2'
																	/>
																</div>
															</div>
															<div className='w-full md:w-1/2'>
																<Text
																	size='m'
																	color='medium'
																>
																	Fecha de caducidad
																</Text>
																<div className='border px-4 py-2 border-h-gray-20 rounded-[4px] mt-2'>
																	<Text
																		size='m'
																		color='medium'
																	>
																		{cardSelected.details.expires}
																	</Text>
																</div>
															</div>
														</div>
														{/* <div className='w-full flex flex-row justify-end '>
											<Button
												onClick={deletePaymentCard}
												sx='md:!w-fit'
												beforeIcon={
													<Trash
														color='#FFF'
														size={32}
													/>
												}
												label='Eliminar tarjeta'
											/>
										</div> */}
													</div>
												)}
										</div>
									))}
									<div className='flex flex-row mb-2 items-start'>
										<Radio
											checked={category === PAYMENT_METHOD.CARD}
											onChange={handleChange}
											value={PAYMENT_METHOD.CARD}
											name='radio-buttons-payments'
											inputProps={{ 'aria-label': 'A' }}
										/>

										<PaymentMethodItem
											label={categoryName(PAYMENT_METHOD.CARD)}
											imgSrc={categoryImage(PAYMENT_METHOD.CARD)}
											imgAlt=''
											description={''}
											showDescription={category === PAYMENT_METHOD.CARD}
											icon={
												<CreditCard
													size={28}
													weight='fill'
												/>
											}
										>
											{category === PAYMENT_METHOD.CARD && (
												<div className='md:w-[40vw] lg:w-[36vw]'>
													<Text
														size='caption'
														sx='mb-4'
														color='medium'
													>
														Paga fácil con cualquier tarjeta bancaria
													</Text>
													<div className='border px-4 py-[18px] rounded-lg'>
														<HeruStripe
															scrollToBottom={scrollToBottom}
															order={order}
															onPaymentMethodSuccess={getCardList}
															showPaymentElement={true}
															setConfrimPayment={setConfrimPayment}
															customerStripeId={customerStripeId}
														/>
													</div>
												</div>
											)}
										</PaymentMethodItem>
									</div>
									{paymentCategories.length > 1 && (
										<>
											<hr className='my-4' />

											<Text
												size='body-2'
												sx='mb-4'
												color='medium'
											>
												Otros métodos de pago
											</Text>
											{paymentCategories
												.filter((item) => item !== PAYMENT_METHOD.CARD)
												.map((item, index) => (
													<div
														className='flex flex-row mb-2 items-center'
														key={`payments-category-${index}`}
													>
														<Radio
															checked={category === item}
															onChange={handleChange}
															value={item}
															name='radio-buttons-payments'
															inputProps={{ 'aria-label': 'A' }}
														/>

														<PaymentMethodItem
															label={categoryName(item)}
															description={categoryDescription(item)}
															imgAlt='Logo del metodo de pago'
															imgSrc={categoryImage(item)}
															showDescription={category === item}
														/>
													</div>
												))}
										</>
									)}
								</div>
							</div>
						</>
					)}
				</>
			)}
		</div>
	);
};

export const CardInfo: React.FC<{
	card: Card;
	hideExpireDate?: boolean;
	infoMessage?: string;
}> = ({ card, hideExpireDate, infoMessage }) => {
	return (
		<div className='flex flex-row items-center justify-between gap-2 w-full'>
			<div className='inline-flex items-center gap-x-1 justify-center'>
				<img
					src={getBrandIcon(card.details.brand)}
					alt='brand'
					className='w-[32px] h-[32px]'
				/>
				<span className='text-sm font-bold inline-flex text-h-neutral-30'>
					<span className='hidden md:block mr-1 text-h-neutral-30'>
						{capitalizeFirstLetter(
							card.details.brand === 'amex'
								? 'American Express'
								: card.details.brand,
						)}{' '}
					</span>
					**** {card.details?.last_four || ''}
				</span>
				{infoMessage && (
				<Tooltip title={infoMessage}>
					<Info className='ml-4 inline-block' weight='bold' />
				</Tooltip>
			)}
			</div>

			{!hideExpireDate && (
				<div>
					<Text
						size='m'
						sx='hidden md:block'
						color='medium'
					>
						Vence el {card.details.expires}
					</Text>
					<Text
						size='m'
						sx='block md:hidden'
						color='medium'
					>
						{card.details.expires}
					</Text>
				</div>
			)}
		</div>
	);
};

export default SelectPaymentMethod;
