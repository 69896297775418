import { createBrowserRouter, Outlet, RouterProvider } from 'react-router-dom';

import Login from '../pages/auth/index';
import Home from '../pages/home';
import NotFound from '../pages/NotFound';
import ProtectedRoutes from './Protected.routes';
import { createTheme, ThemeProvider } from '@mui/material';
import Onboarding from '../pages/onboarding';
import ROUTES from '../shared/constants/routes';
import Shopping from '../pages/shopping';
import Plan from '../pages/plan';
import SuccessfulPurchase from '../pages/successfulPurchase';
import Profile from '../pages/profile';
import ServiceStatus from '../pages/serviceStatus';
import TaxProfilePage from '../pages/taxProfile';
import AsatFormSuccess from '../pages/serviceStatus/components/organisms/AsatFormSuccess';
import AccountHeru from '../pages/accountHeru';
import PaymentMethods from '../pages/paymentMethods';
import Referrals from '../pages/referrals';
import UberFlow from '../pages/uberFlow';
import FiscalDocuments from '../pages/fiscalDocuments';
import TaxesAndInvoices from '../pages/taxesAndInvoices';
import RappiCertificates from '../pages/rappiCertificates';
import Invoicing from '../pages/refactorInvoicing';
import MySubscriptions from '../pages/mySubscriptions';
import PendingReferences from '../pages/pendingReferences';

import FiscalFlowTransactions from '../pages/fiscalFlowTransactions';
import Intro from '../pages/intro';
import OnboardingSuccessfulPurchase from '../pages/onboardingSuccessfulPurchase';
import PlansAndPricesV2 from '../pages/plansAndPricesV2';
import OrganizationSuccessLink from '../pages/OrganizationSuccessLink';
import RegisterPage from '../pages/registerPage';

const router = createBrowserRouter([
	{
		id: 'home',
		path: '/',
		errorElement: <NotFound />,
		element: <ProtectedRoutes redirectPath='/login' />,
		children: [
			{
				id: 'root',
				path: ROUTES.ROOT,
				element: <Home />,
			},
			{
				id: 'userProfile',
				path: ROUTES.USER_PROFILE,
				element: <Profile />,
			},
			{
				id: 'plans',
				path: ROUTES.PLANS,
				element: <Plan />,
			},
			{
				id: 'checkout',
				path: ROUTES.CHECKOUT,
				element: <Home />,
			},
			{
				id: 'successful-purchase',
				path: ROUTES.SUCCESSFUL_PURCHASE,
				element: <SuccessfulPurchase />,
			},
			{
				id: 'register',
				path: ROUTES.REGISTER,
				element: <RegisterPage />,
			},
			{
				id: 'onboarding',
				path: ROUTES.ONBOARDING,
				element: <Onboarding />,
			},
			{
				id: 'invoicing',
				path: ROUTES.INVOICING,
				element: <Outlet />,
				children: [
					{
						id: 'invoicing-root',
						path: ROUTES.INVOICING,
						element: <Invoicing />,
					},
					{
						id: 'invoicing-detail',
						path: ROUTES.INVOICE_DETAIL,
						element: <Invoicing />,
					},
				],
			},
			{
				id: 'taxes',
				path: ROUTES.TAXES,
				element: <TaxesAndInvoices />,
			},
			{
				id: 'taxProfile',
				path: ROUTES.TAX_PROFILE,
				element: <TaxProfilePage />,
			},
			{
				id: 'cart',
				path: ROUTES.CART,
				element: <UberFlow />,
			},
			{
				id: 'shopping',
				path: ROUTES.SHOPPING,
				element: <Shopping />,
			},
			{
				id: 'serviceStatus',
				path: ROUTES.SERVICE_STATUS,
				element: <Outlet />,
				children: [
					{
						id: 'serviceStatus-root',
						path: ROUTES.SERVICE_STATUS,
						element: <ServiceStatus />,
					}
				],
			},
			{
				id: 'fft',
				path: ROUTES.GENERAL_FFT,
				element: <FiscalFlowTransactions />,
			},
			/* {
				id: 'details-fft',
				path: ROUTES.DETAILS_FFT,
				element: <DetailsFft />,
			}, */
			{
				id: 'asatSuccess',
				path: ROUTES.ASAT_SUCCESS,
				element: <AsatFormSuccess />,
			},

			{
				id: 'accountHeru',
				path: ROUTES.ACCOUNT_HERU,
				element: <AccountHeru />,
			},
			{
				id: 'paymentMethods',
				path: ROUTES.PAYMENT_METHODS,
				element: <PaymentMethods />,
			},
			{
				id: 'referrals',
				path: ROUTES.REFERRALS,
				element: <Referrals />,
			},
			{
				id: 'fiscalDocuments',
				path: ROUTES.FISCAL_DOCUMENTS,
				element: <FiscalDocuments />,
			},
			{
				id: 'rappiCertificates',
				path: ROUTES.RAPPI_CERTIFICATES,
				element: <RappiCertificates />,
			},
			{
				id: 'pendingReferences',
				path: ROUTES.PENDING_REFERENCES,
				element: <PendingReferences />,
			},
			{
				id: 'mySubscriptions',
				path: ROUTES.MY_SUBSCRIPTIONS,
				element: <MySubscriptions />,
			},
			{
				id: 'plansAndPrice',
				path: ROUTES.PLANS_AND_PRICES,
				element: <PlansAndPricesV2 />,
			},
			{
				id: 'intro',
				path: ROUTES.INTRO,
				element: <Intro />,
			},
			{
				id: 'onboarding-successful-purchase',
				path: ROUTES.ONBOARDING_SUCCESSFUL_PURCHASE,
				element: <OnboardingSuccessfulPurchase />,
			},
			{
				id: 'organizationSuccessLink',
				path: ROUTES.ORGANIZATION_SUCCESS_LINK,
				element: <OrganizationSuccessLink />,
			},
		],
	},
	{
		id: 'login',
		path: ROUTES.LOGIN,
		element: <Login />,
	},
]);

const darkTheme = createTheme({
	palette: {
		mode: 'light',
	},
});

const Navigation = () => {
	return (
		<ThemeProvider theme={darkTheme}>
			<RouterProvider router={router} />
		</ThemeProvider>
	);
};

export default Navigation;
