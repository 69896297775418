const HERU_API = {
	//? AUTH
	SEND_OTP_CODE: '/user-interaction/users_v2/send-otp',
	VERIFY_OTP_CODE: '/user-interaction/users_v2/verify-otp',
	ATTRIBUTE_USER: '/user-interaction/users_v2/attribution',
	GENERATE_OTP: '/generate-otp',

	//? USER
	USER_PROFILE: '/user-interaction/users/profile',
	UPDATE_USER_PROFILE: '/user-interaction/users/update-profile',
	NEW_UPDATE_USER_PROFILE: '/user-interaction/users_v2',
	COMPLIANCE_DOCUMENT_TC: '/tax/compliance/document/doc_type/T&Cs',
	COMPLIANCE_LOG: '/tax/compliance/log',
	PROFILE_FISCAL_RETENTIONS: '/tax/fiscal-profile/retention',
	HERU_API_DESACTIVE_ACCOUNT: '/user-interaction/users/deactivate',
	USER_INTERACTION_EVENTS: '/user-interaction/events',

	//? AI
	ONBOARDING_WELCOME: '/bi/heru-core-gpt-service/gpt/welcome',
	ONBOARDING_RECOMMENDATION: '/bi/heru-core-gpt-service/gpt/recommendation',

	//? EXPERIMENTS
	EXPERIMENTS: '/utils/event/experiments/assignments',

	//? FISCAL LINK
	HERU_API_DELETE_FINANCIAL_LINK: (userId: number) =>
		`/tax/fiscal-profile/taxpayer/link/${userId}`,
	HERU_API_FINANCIAL_LINK: '/tax/fiscal-profile/taxpayer/link',
	HERU_API_FISCAL_CREDENTIALS_HERU: '/crawling-extractions/link',
	HERU_API_FISCAL_CREDENTIALS_EFIRMA_HERU:
		'/crawling-extractions/link-efirma-v2',
	HERU_API_SAT_USER_EXTRACTION_CHECK: '/satws/request_extraction_check_limit',
	HERU_API_SIGNIN_SAT_LINK_FLOW: '/crawling-extractions/crawl/link',
	HERU_API_SIGNIN_SAT_LINK_POLLING: (userId: number) =>
		`/signin/link/polling/${userId}`,

	//? Ecommerce
	SALES_RECEIPTS: '/sales/receipts',
	HERU_API_GET_SHOPPING_CART: (shoppingCartId: number) =>
		`/sales/shopping-carts/${shoppingCartId}`,
	HERU_API_PRODUCTS: '/sales/products',
	HERU_API_CREATE_CART: '/sales/plans/orders',
	HERU_API_ORDERS_PENDING: '/sales/plans/orders/pending',
	HERU_API_ORDERS_PENDING_DETAIL: (orderId: number) =>
		`/sales/plans/orders/detail/${orderId}`,
	HERU_API_CANCEL_SUBSCRIPTION: (subscription_id: string = '') =>
		`/subscriptions${subscription_id}`,
	HERU_API_CANCEL_SUBSCRIPTION_NOW: (subscription_id: string = '') =>
		`/subscriptions${subscription_id}?now=true`,
	HERU_API_ORDER_FAILED:
		'/sales/plans/orders?status=open&status=failed&is_renewal=true',
	HERU_API_ACQUISITIONS: '/sales/plans/acquisitions',
	HERU_API_SUBSCRIPTION_NEW: '/sales/plans/subscriptions',
	HERU_API_ALL_SUBSCRIPTION: '/sales/subscriptions',
	HERU_API_SUBSCRIPTION: '/subscriptions',
	HERU_API_SUBSCRIPTION_EVENTS: (id: string) => `/subscriptions/${id}/event`,
	HERU_API_SUBSCRIPTION_BY_REGIMES: '/subscriptions/regimes',
	HERU_API_SHOPPING_CART_REFRESH: (params: string) =>
		`/sales/shopping-carts/refresh${params}`,
	HERU_API_SHOPPING_CART_CHANGE_ITEM: (cartId: number, itemId: number) =>
		`/sales/shopping-carts/${cartId}/shopping-items/${itemId}/change`,
	HERU_API_SHOPPING_CART_BULK: (cartId: number) =>
		`/sales/shopping-carts/${cartId}/shopping-items/bulk?with_refresh=true`,
	HERU_API_SHOPPING_CART_LAST: `/sales/shopping-carts/last-with-detail`,
	SHOPPING_CARTS: '/sales/shopping-carts',

	//? UTILS
	ASSIGNMENTS: '/utils/event/experiments/feature-flag/assignments',
	HERU_API_I18N_ERRORS: '/catalogs/errors?language=es',

	//? PAYMENTS
	GET_CUSTOMER: `/sales/payments/customers?platform_id=2`,
	CREATE_CUSTOMER: `/sales/payments/customers/`,
	CLIENT_INTENT: `/sales/payments/stripe/create-intent`,
	HERU_CORE_PAYMENTS_LIST_CARDS: '/sales/payments/tokens',
	HERU_CORE_PAYMENTS_DELETE_CARD: (tokenId: string) =>
		`/sales/payments/tokens/${tokenId}`,
	HERU_CORE_PAYMENTS_DEFAULT_CARD: (pm_id: string) =>
		`/sales/tokens/default/${pm_id}`,
	PROCESS_CLIENT_SECRET_PAYMENT: (
		userId: number,
		id: number,
		renewSubscription: boolean,
	) =>
		`/sales/payments/payments/users/${userId}/orders/${id}/processv2?renew_subscriptions=${renewSubscription}`,
	HERU_CORE_PAYMENTS_SHOPPING_PROCESSV2: (cartId: number) =>
		`/sales/heru-core-sales-read-level/payments/shopping-carts/${cartId}/processv2`,

	HERU_CORE_PAYMENTS_SHOPPING_PROCESSV2_PAYPAL: (cartId: number) =>
		`/sales/heru-core-sales-read-level/payments/shopping-carts/${cartId}/paypal`,

	HERU_CORE_PAYMENTS_SHOPPING_PREPARE_PROCESSV2: (cartId: number) =>
		`/sales/shopping-carts/${cartId}/prepare-processv2`,
	//? Invoicing
	INVOICE_SUMMARY: `/invoicing/summary_v2`,
	INVOICE_GENERATE: `/invoicing/invoices_4_0`,
	INVOICE_REGIMES: `/invoicing/invoices_4_0/regimes`,
	INVOICE_CERTIFICATES: `/invoicing/certificates`,
	INVOICE_RECEIVERS: `/invoicing/invoices_4_0/users/receivers`,
	INVOICE_USER_PRODCUTS: `/invoicing/invoices_4_0/users/products`,
	INVOICE_SAVE_RECEIVER: `/invoicing/invoices_4_0/users/receivers`,
	INVOICE_DETAIL: (id: string) => `/invoicing/invoices/${id}/detail`,
	INVOICE_USER_ISSUED: (id: number) => `/invoicing/invoices/user/${id}/issued`,
	INVOICE_CATALOGS:
		'https://d16nv9v9z1ihe6.cloudfront.net/INVOICE_CATALOG/tos3.json',
	INVOICE_CANCEL: (id: string, cancelMotive: number) =>
		`/invoicing/invoices/${id}?cancellation_motive=${cancelMotive}`,
	INVOICE_DETAIL_SEND_EMAIL: (id: string) =>
		`/invoicing/invoices/${id}/send_by_email`,
	INVOICE_USER_HAS: (id: number) =>
		`/invoicing/invoices/user/${id}/has_heru_invoices`,
	INVOICE_DIGITAL_STATUS: (userId: number) =>
		`/invoicing/certificates/users/${userId}`,
	INVOICE_VALIDATION_FIELDS: ({
		rfc,
		name,
		postal_code,
		tax_regime,
		cfdiUse,
	}: Record<string, string>) =>
		`/tax/fiscal/operations/cfdi_fiscal_validator/?rfc=${rfc}&nombre=${name}&domicilio_fiscal=${postal_code}&regimen_fiscal=${tax_regime}${cfdiUse}`,
	HERU_API_CERTIFICATES: '/b2b/certificates',

	//?TAXES
	HERU_API_FINANCIAL_TAX_PAYERS: (userId: number) =>
		`/tax/heru-core-fiscal-read-level-service/taxpayer/users/${userId}/taxpayers`,
	HERU_API_FINANCIALS_MOVEMENTS: '/tax/financials/movements',
	HERU_API_FISCAL_OBLIGATIONS: (userId: number) =>
		`/tax/heru-core-fiscal-read-level-service/taxpayer/users/${userId}/obligations`,
	HERU_API_FISCAL_DECLARATIONS_STATUS: '/tax/fiscal/declarations/status',
	HERU_API_VISOR_DEDUCTIONS: (rfc: string) =>
		`/tax/visor/deductions?rfc=${rfc}`,
	HERU_API_FISCAL_SIGNATURES: (id: number) =>
		`/invoicing/users/${id}/e-signatures`,
	HERU_FISCAL_DOWNLOAD_DOCUMENTS: (
		param: 'private_key' | 'certificate' | 'password',
		documentType: 'e-signatures' | 'certificates',
	) => `/invoicing/${documentType}/download?${param}=true`,
	HERU_API_FISCAL_DELETE_DOCUMENTS: (
		documentType: 'e-signatures' | 'certificates',
	) => `/invoicing/${documentType}`,
	HERU_API_FISCAL_UPLOAD_EFIRMA: '/crawling-extractions/upload-efirma',

	HERU_API_FISCAL_CREDENTIALS: '/tax/fiscal/credentials/profile',
	HERU_API_FISCAL_DECLARATIONS: (userId: number) =>
		`/heru-core-fiscal-read-level-service/declarations/overview?userId=${userId}`,
	HERU_API_FISCAL_DECLARATION_DETAIL: (declarationId: number, regime: string) =>
		`/heru-core-fiscal-read-level-service/declarations/${regime}/${declarationId}`,
	REGIMES_INCOMES: `/tax/fiscal-profile/taxpayer/regimes`,
	REMOVE_LINKS: (userId: number, type: string) =>
		`/tax/fiscal-profile/taxpayer/link/${userId}/${type}`,

	//? TAXES V1
	DASHBOARD_MONTHLY_DECLARATION_OVERVIEW: `/tax/fiscal-profile/declarations/montly-overview`,
	DASHBOARD_YEARLY_DECLARATIONS_OVERVIEW: `/tax/fiscal-profile/declarations/annual-overview`,
	DASHBOARD_DECLARATION_DETAIL:
		'/tax/fiscal-profile/declarations/montly-overview/detail',
	DASHBOARD_ANNUAL_FISCAL_OVERVIEW: `/fiscal-profile/declarations/montly-overview`,
	DASHBOARD_EXTRACTIONS_ON_DEMAND: `/crawling-extractions/extractions`,

	//? Fiscal documents
	HERU_API_FISCAL_DOCUMENTS: '/crawling-extractions/extractions/ondemand',
	USER_INVOICES: '/tax/fiscal-profile/invoices',
	USER_RETENTION_CERTIFICATES: '/tax/fiscal-profile/retention',
	USER_DECLARATION_RECEIPTS: `/tax/fiscal-profile/declarations/acuses`,
	USER_FISCAL_DOCUMENT_DETAIL: '/tax/fiscal-profile/fiscal-documents',

	//? Heru Core Payments
	HERU_API_DEFAULT_PAYMENT: '/sales/plans/shopping-payment/payment-method',
	HERU_API_DEFAULT_STRIPE_BILLING: (pm_id: string, subsId: number) =>
		`/sales/subscriptions/${subsId}/payment-method/${pm_id}`,
	HERU_API_PAYMENT_ON_DEMAND: (sub_id: number) =>
		`/sales/subscriptions/${sub_id}/renew-attempt`,

	//? SERVICE STATUS
	HERU_API_SERVICE_STATUS:
		'/user-interaction/heru-core-status-business-process/services',
	HERU_API_SERVICES_STATUS_LIST_BLOCKED_CART: `/sales/plans/orders/?status=open&status=failed&shopping_cart_treatment=ACQUISITION_FOR_RENEWAL_FAILED&source_channel=web`,
	HERU_API_SERVICES_STATUS_CREATE_BLOCKED_CART: '/sales/plans/orders/',
	HERU_API_SERVICES_STATUS_GET_PRICE_BLOCKED_CART: (subsId: number) =>
		`/sales/plans/subscriptions/${subsId}/summary-unlock-declaration`,
	HERU_API_SERVICES_STATUS_DELETE_ITEM_BLOCKED_CART: (
		orderId: number,
		itemId: number,
	) => `/sales/plans/orders/${orderId}/items/${itemId}`,
	HERU_API_SERVICE_STATUS_ACCOUNTANTS: '/user-interaction/users/accountants',
	HERU_API_SERVICE_STATUS_COMPLETE_STEP: (
		serviceId: number,
		serviceStepId: number,
	) =>
		`/user-interaction/heru-core-status-business-process/services/${serviceId}/service-steps/${serviceStepId}/fields`,
	HERU_API_SERVICES_STATUS_DECLARATIONS_DOCUMENTS: (
		year: number,
		month?: number,
	) =>
		`/tax/fiscal/users/declaration_documents/${year}${
			month ? `/${month}` : ''
		}`,
	HERU_API_SERVICES_STATUS_ANNUAL_DECLARATIONS_DOCUMENTS: (year: number) =>
		`/tax/fiscal/users/annual/declarations/declaration-documents?year=${year}`,

	//? USER INTERACTION
	HERU_API_USER_INTERACTION: '/user-interaction/feedbacks',
	HERU_API_PAYMENT__ORDERS_PROCESS: (orderId: number) =>
		`/sales/payments/payments/orders/${orderId}/process`,
	HERU_API_ORGANIZATIONS: '/user-interaction/organizations',
	HERU_API_ORGANIZATION_LINK_ACCOUNT: (org: number, userId: number) =>
		`/user-interaction/users_v2/${userId}/organizations/tiers/${org}/assign`,
	HERU_API_UBER_LOGIN: '/user-interaction/organizations/uber/login/embedded',
	HERU_API_UBER_LOGIN_CONNECT: (
		code: string,
		state: string,
		redirectUri: string,
	) =>
		`/user-interaction/organizations/uber/connect/embedded?redirect_uri=${redirectUri}&code=${code}&state=${state}`,
	HERU_API_ORGANIZATION_TIER: (org: number) =>
		`/user-interaction/organizations/${org}/tier`,
	HERU_API_AUTH_ORGANIZATION:
		'/user-interaction/organizations/uber/auth-credential',

	//? GROWTHBOOK
	HERU_API_GROWTHBOOK_EXPERIMENTS_ASSIGNMENTS:
		'/utils/event/experiments/assignments',

	//? ERRORS
	HERU_API_ERRORS: '/utils/errors/catalogs?language=es',

	//? FFT
	HERU_API_FFT: (userId: number) =>
		`/crawling-extractions/flow/fft/v2?user_id=${userId}`,
	HERU_API_FFT_DETAILS: (fftId: number) =>
		`/crawling-extractions/flow/fft/${fftId}`,
	HERU_API_FFT_INFO_PROFILES: '/user-interaction/users_v2/heru/profiles',
	HERU_API_FFT_ALL: (userId: number, year?: string) =>
		`/tax/lucy/services/${userId}${year ? `?year=${year}` : ''}`,
	HERU_API_FFT_LAST: (userId: number) =>
		`/crawling-extractions/flow/fft/users/${userId}/last-fft`,

	//? IA
	HERU_API_IA_SAMANTHA_CHAT: '/ai/samantha/chat',
	HERU_API_IA_SAMANTHA_ONBOARDING: (userId: number) =>
		`/ai/samantha/onboarding/${userId}`,
	HERU_API_IA_SAMANTHA_ANALYSIS: (userId: number, onboardingId: number) =>
		`/ai/samantha/onboarding-analizer/${userId}/${onboardingId}`,

	//? SALES
	HERU_API_SALES_PERIODS_ADQUIRED: '/sales/periods/acquireds',
	HERU_API_USER_FISCAL_DOCUMENT_UPLOAD: '/tax/fiscal-profile/upload_xml',
};

export default HERU_API;
