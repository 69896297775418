import { RootState } from '../../redux/store';
import { useDispatch, useSelector } from 'react-redux';
import { CircularProgress } from '@mui/material';
import PlanOverview from './organisms/PlanOverview';
import ProductCardSimple from './organisms/ProductCardSimple';
import useAddToCart from '../../hooks/useAddToCart';
import imgBannerAnnualPlan from '../../assets/img/png/annual-plan.png';
import { PRODUCTS_SLUG } from '../../shared/constants/SlugsProducts';
import { formatPrice } from '../../shared/utils/general.util';
import { setShowModalCheckout } from '../../redux/slices/plans.slice';
import { DATES } from '../../shared/utils/dates';
import { Info } from '@phosphor-icons/react';
import Text from '../../components/atoms/Text';
import { getDiscountPeriod } from './molecules/AnnualCountdown';
import AnnualCountdown from './molecules/AnnualCountdown';

const ANNUAL_BENEFITS = [
	'Usamos nuestros modelos más avanzados de inteligencia artificial buscando optimizar el resultado de tu declaración anual a tu favor.',
	'Recibirás un análisis detallado de la declaración anual 2025 presentada junto con su acuse y detalle correspondiente.',
	'En caso de que el resultado de tu declaración anual sea un saldo a favor, gestionaremos ante el SAT la solicitud correspondiente.',
	'Actualizaremos tu tablero de obligaciones fiscales reflejando el cumplimiento de tu declaración.',
];

export const AnnualCard = () => {
	const dispatch = useDispatch();
	const { handleAddCustomProduct, isLoading: isLoadingAddToCart } =
		useAddToCart();

	const { products } = useSelector((state: RootState) => state.productList);

	const regularizationProducts = products?.find(
		(product) => product.name === 'Declaración Anual',
	)?.products;

	const subscriptionProduct = regularizationProducts?.find(
		(product) => product.slug === PRODUCTS_SLUG.ANNUAL_DECLARATION,
	);

	const price = subscriptionProduct?.product_versions?.[0]?.price;
	const priceWithDiscount = price
		? price * (1 - getDiscountPeriod().discount)
		: 0;

	const handleAddToCart = async () => {
		const payload = [
			{
				product_version_id: subscriptionProduct?.default_product_version_id,
				additional_information: {
					regimes: [],
					year: DATES.currentYear - 1,
				},
			},
		];

		const response = await handleAddCustomProduct({
			payload: payload,
			navigateToCart: false,
			resetOrders: true,
		});

		if (!response) return;
		dispatch(setShowModalCheckout(true));
	};

	if (!subscriptionProduct) {
		return (
			<div className='flex items-center justify-center mt-10'>
				<CircularProgress size={25} />
			</div>
		);
	}

	return (
		<div className=''>
			<AnnualCountdown page='annual_plans' />
			{/* ======== PRODUCT CARD ======== */}
			<div className='flex flex-col gap-8'>
				<ProductCardSimple
					isLoading={isLoadingAddToCart?.products}
					imgBanner={imgBannerAnnualPlan}
					name={
						<>
							Declaración <br /> anual 2025
						</>
					}
					discount='$1,500'
					priceDescription={getDiscountPeriod().description}
					description='Cubre la presentación de tu declaración anual 2025. Anticipa tu pago y asegura una preparación a tiempo.'
					benefitsTitle='Tu declaración anual incluye'
					benefits={ANNUAL_BENEFITS}
					ctaAction={handleAddToCart}
					ctaLabel='Contratar declaración anual 2025'
					price={formatPrice(priceWithDiscount)}
					className='max-w-[400px] mx-auto'
				/>
				<div className='!text-h-neutral-40 p-2 rounded-lg bg-h-neutral-90/50 flex items-start gap-2 sm:-mt-4 max-w-[400px] mx-auto'>
					<Info className='min-w-5 min-h-5' />
					<div className='flex flex-col gap-1'>
						<Text size='caption'>Importante</Text>
						<Text
							size='mini-1'
							color='medium'
							weight='light'
						>
							Recuerda que para presentar tu declaración anual, todas tus
							declaraciones mensuales deben estar correctamente presentadas. En
							caso de que no lo estén, uno de nuestros contadores se comunicará
							contigo para ayudarte a regularizar o corregir las mismas y así
							poder presentar tu declaración anual.
						</Text>
					</div>
				</div>
			</div>
		</div>
	);
};

const AnnualPlanSection = () => {
	return (
		<div className='max-w-[860px] mx-auto relative'>
			<PlanOverview
				title='Declaración anual 2025'
				description={
					<>
						Asegura hoy tu declaración anual a presentar en{' '}
						<b className='text-h-neutral-0 font-bold'>
							abril 2025 por los ingresos durante 2024.
						</b>{' '}
						Nuestros expertos fiscales junto con nuestra tecnología te brindarán
						un proceso claro, sencillo y sin preocupaciones para cumplir
						correctamente con el SAT.
					</>
				}
			/>

			{/* ======== COUNTDOWN ======== */}
			<div className='px-2'>
				<AnnualCard />
			</div>
		</div>
	);
};

export default AnnualPlanSection;
