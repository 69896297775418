import { useEffect, useState, Dispatch, SetStateAction } from 'react';
import ProductCard from './organisms/ProductCard';
import { Product } from '../../@types/Products.type';
import { useSelector } from 'react-redux';
import { RootState } from '../../redux/store';
import TogglePeriodicity from './organisms/TogglePeriodicity';
import { useMediaQuery } from '@mui/material';
import PlanOverview from './organisms/PlanOverview';
import PlansAndPrices from '../plansAndPrices';
import { Info } from '@phosphor-icons/react';
import Text from '../../components/atoms/Text';
import Modal from '../../components/molecules/Modal/Modal';
import PrimaryButton from '../../components/atoms/Button';
import { useStorage } from '../../shared/utils/general.util';
import STORAGE_KEYS from '../../shared/constants/storage';
import { PlatformOS } from '../../redux/slices/profile.slice';

const SubscriptionsPlanSection = ({
	showLegacyPlans,
	setShowLegacyPlans,
}: {
	showLegacyPlans: boolean;
	setShowLegacyPlans: Dispatch<SetStateAction<boolean>>;
}) => {
	const [subscriptionProducts, setSubscriptionProducts] = useState<
		Product[] | null
	>(null);
	const [showModalAlertNewVersion, setShowModalAlertNewVersion] = useState(
		useStorage().getItem('newPlansAlertViewed') !== 'true',
	);
	const isFromIOS =
		useStorage().getItem(STORAGE_KEYS.PLATFORM_OS) === PlatformOS.IOS;
	const { products } = useSelector((state: RootState) => state.productList);
	const { samanthaAnalysis } = useSelector((state: RootState) => state.ai);
	const { haveLegacySubscription } = useSelector(
		(state: RootState) => state.plans,
	);
	const [isLoadingClickProduct, setIsLoadingClickProduct] = useState(false);

	const isMobile = useMediaQuery('(max-width: 768px)');

	useEffect(() => {
		const subscriptionProductsFiltered = products?.find(
			//@ts-ignore
			(item) => item?.name === 'Planes',
		)?.products;

		const suscriptionProductsWithRecommendedPlan =
			subscriptionProductsFiltered?.map((product) => {
				if (product.slug === samanthaAnalysis?.slug) {
					return { ...product, recommended: true };
				}
				return product;
			});

		if (suscriptionProductsWithRecommendedPlan) {
			//@ts-ignore
			const orderByPrice = suscriptionProductsWithRecommendedPlan.toSorted(
				(a: Product, b: Product) => a.price - b.price,
			);

			const orderByRecommended = orderByPrice.toSorted(
				(
					a: Product & { recommended: boolean },
					b: Product & { recommended: boolean },
				) => (a.recommended ? -1 : b.recommended ? 1 : 0),
			);

			if (isMobile) {
				setSubscriptionProducts(orderByRecommended);
			} else {
				setSubscriptionProducts(orderByPrice);
			}
		}
	}, [products, isMobile]);

	const handleCloseModalAlertNewVersion = () => {
		localStorage.setItem('newPlansAlertViewed', 'true');
		setShowModalAlertNewVersion(false);
	};

	return (
		<div>
			<Modal
				open={showModalAlertNewVersion && haveLegacySubscription}
				onClose={handleCloseModalAlertNewVersion}
				showCloseButton={false}
				sx='!p-4 rounded-lg overflow-hidden max-w-[calc(100dvw-20px)] sm:max-w-[460px] w-full'
			>
				<div className='flex flex-col gap-2 items-center'>
					<Info
						weight='bold'
						className='text-h-primary size-8 sm:size-12'
					/>
					<Text
						sx='!block text-center'
						weight='bold'
					>
						Estás explorando nuestros nuevos planes contables
					</Text>
					<Text
						sx='!block text-center'
						size='caption'
					>
						A continuación verás nuestros nuevos planes contables. Estos planes
						no afectan actualmente la entrega del servicio de acuerdo a tu plan
						contratado. Sin embargo, ten en cuenta que tu plan actual será
						migrado a uno de nuestros nuevos planes en el 2025. Te mantendremos
						al tanto de la migración en tu perfil de cliente, WhatsApp y correo
						electrónico.
					</Text>
					<PrimaryButton
						onClick={handleCloseModalAlertNewVersion}
						label='Entiendo'
						sx='!h-10 !bg-h-primary mt-4'
					/>
				</div>
			</Modal>

			<div className='w-full mx-auto max-w-[1200px]'>
				{showLegacyPlans ? (
					<div>
						<div
							onClick={() => setShowLegacyPlans(false)}
							className='flex gap-2 items-center bg-h-success-90 p-2 sm:p-4 rounded-lg shadow text-h-success-30 mx-2'
						>
							<Info
								className='size-6 sm:size-8 min-w-[24px]'
								weight='fill'
							/>
							<div className='flex flex-col gap-1'>
								<Text
									size='caption'
									weight='bold'
								>
									Estás explorando nuestros antiguos planes
								</Text>
								<Text
									size='caption'
									sx='!block'
								>
									Recuerda que tu plan actual será migrado a uno de nuestros
									nuevos planes en el 2025. Te mantendremos al tanto de la
									migración en tu perfil de cliente, WhatsApp y correo
									electrónico.{' '}
									<span className='underline opacity-80 cursor-pointer'>
										Para ver la nueva versión de nuestros planes haz click aquí.
									</span>
								</Text>
							</div>
						</div>
						<PlansAndPrices showTabs={false} />
					</div>
				) : (
					<>
						{haveLegacySubscription && (
							<div
								onClick={() => setShowLegacyPlans(true)}
								className='flex gap-2 items-center bg-white p-2 sm:p-4 rounded-lg shadow mx-2 mb-4'
							>
								<Info className='text-h-primary size-6 sm:size-8 min-w-[24px]' />
								<div className='flex flex-col gap-1'>
									<Text
										size='caption'
										weight='bold'
									>
										Estás explorando nuestros nuevos planes contables
									</Text>
									<Text
										size='caption'
										sx='!block'
									>
										Recuerda que tu plan actual será migrado a nuestro de
										nuestros nuevos plan actual no se verán afectados{' '}
										<span className='underline opacity-80 cursor-pointer'>
											haz click aquí para volver a tu plan anterior
										</span>
									</Text>
								</div>
							</div>
						)}
						<PlanOverview
							title='Selecciona el plan que más se adecue a tu actividad económica'
							description='Todos nuestros planes están diseñados para quitarte el estrés de cumplir con tus obligaciones fiscales con la ayuda de nuestros contadores expertos y nuestra tecnología.'
						/>

						<TogglePeriodicity
							className={`flex items-center gap-2 w-full md:-mt-6 justify-center md:justify-end py-4 sticky top-12 -left-4 bg-h-neutral-94 z-10 
								${isFromIOS ? 'top-[90px]' : ''}`}
						/>
						<div className='flex flex-col lg:flex-row items-start gap-2 sm:gap-3 xl:gap-8 mt-2 px-3 mb-4'>
							{subscriptionProducts?.map((product, index) => (
								<ProductCard
									product={product}
									key={`product-${index}`}
									recommended={product.slug === samanthaAnalysis?.slug}
									className='lg:min-h-[760px]'
									isLoadingClickProduct={isLoadingClickProduct}
									setIsLoadingClickProduct={setIsLoadingClickProduct}
								/>
							))}
						</div>
					</>
				)}
			</div>
		</div>
	);
};

export default SubscriptionsPlanSection;
