// @ts-nocheck
import React, { PropsWithChildren, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
	Navigate,
	Outlet,
	useLocation,
	useNavigate,
	useSearchParams,
} from 'react-router-dom';
import clevertap from 'clevertap-web-sdk';
import type { RootState } from '../redux/store';
import { addToken, login } from '../redux/slices/auth.slice';
import MainLayout from '../layouts/MainLayout';
import { PlatformOS, setProfile } from '../redux/slices/profile.slice';
import { getUserProfile } from '../services/profile.service';
import { HERU_UTM } from '../shared/constants/utms';
import LOCAL_STORAGE_KEY from '../shared/constants/localStorageKey';
import { Profile } from '../@types/Profile.type';
import {
	getBrowser,
	isLocalStorageEnabled,
	isSessionStorageEnabled,
	useStorage,
} from '../shared/utils/general.util';
import growthbookFile from '../shared/constants/growthbook';
import { setOverwriteUtm, updateUTMs } from '../redux/slices/cart.slice';
import { getOnboardingIA } from '../services/heru-ai.service';
import { IOnboardingResponseSamantha } from '../@types/IA.type';
import { setIsOnboarding } from '../redux/slices/config.slice';
import { useFeatureIsOn } from '@growthbook/growthbook-react';
import GROWTHBOOK_FEATURE_FLAGS from '../shared/utils/growthbook.util';
import { getProducts } from '../services/plans.service';
import { addProducts } from '../redux/slices/products.slice';
import ROUTES from '../shared/constants/routes';
import SESSION_STORAGE_KEY from '../shared/constants/sessionStorageKey';
import STORAGE_KEYS from '../shared/constants/storage';
import URL_PARAMS from '../shared/constants/urlParams';
import {
	setSelectedInvoiceCategory,
	setShowModalCheckout,
} from '../redux/slices/plans.slice';
import { PLAN_CATEGORY } from '../pages/plansAndPrices';
import { getUserSuscriptions } from '../services/suscription.service';
import { fetchAcquisitions } from '../services/shopping.service';

type Props = {
	redirectPath: string;
};

declare global {
	interface Window {
		clarity: any;
	}
}

const ProtectedRoutes: React.FC<PropsWithChildren<Props>> = ({
	redirectPath,
}) => {
	const [searchParams] = useSearchParams();

	const isUser = useSelector((state: RootState) => state.auth.isLogged);
	const [tokenExist, setTokenExist] = useState(true);
	const userProfile = useSelector((state: RootState) => state.profile.user);
	const { tokenSession } = useSelector((state: RootState) => state.auth);
	const { utmInfo } = useSelector((state: RootState) => state.cart);

	const userToken = isLocalStorageEnabled()
		? localStorage.getItem(LOCAL_STORAGE_KEY.TOKEN)
		: tokenSession;

	const onboardingIsMandatory = useFeatureIsOn(
		GROWTHBOOK_FEATURE_FLAGS.HIDE_CLOSE_ONBOARDING,
	);
	const location = useLocation();
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const storage = useStorage();

	const getOnboardingIAProgress = async (userId: number) => {
		try {
			if (!userToken) return;
			const response: IOnboardingResponseSamantha | any = await getOnboardingIA(
				userId,
				tokenSession,
			);

			const percentage = response?.percentage?.percentage;

			const onboardingFailedDate = useStorage().getItem(
				STORAGE_KEYS.ONBOARDING_FAILED_DATE,
			);

			const memberBeforeUpdateSamantha =
				userProfile?.joined_at &&
				new Date(userProfile.joined_at) < new Date('2020-02-14T00:00:00Z');

			const isOneDayAfterOnboardingFailed = onboardingFailedDate
				? new Date(onboardingFailedDate).getTime() + 24 * 60 * 60 * 1000 >
					new Date().getTime()
				: true;

			const subscriptions = await getUserSuscriptions(userToken);
			const acquisitions = await fetchAcquisitions(userToken);

			const notHavePurchases =
				acquisitions.length === 0 || subscriptions.length === 0;

			if (
				percentage !== 100 &&
				onboardingIsMandatory &&
				isOneDayAfterOnboardingFailed &&
				!searchParams.get('skip_samantha') &&
				!memberBeforeUpdateSamantha &&
				notHavePurchases
			) {
				dispatch(setIsOnboarding(true));
				navigate('/intro');
			}
		} catch (error) {}
	};

	const getAllProducts = async () => {
		try {
			const data = await getProducts(tokenSession);
			dispatch(addProducts(data));
		} catch (error) {
		} finally {
		}
	};

	const getStorageProfile = async () => {
		try {
			const userInfo: Profile = await getUserProfile(tokenSession);
			dispatch(setProfile(userInfo));
			clevertap.onUserLogin.push({
				Site: {
					Name: `${userInfo?.first_name} ${userInfo?.father_surname}`,
					Identity: userInfo?.id,
					Email: userInfo?.email,
					Phone: `${userInfo?.country_code}${userInfo?.cellphone}`,
				},
			});

			growthbookFile?.setAttributes({
				loggedIn: true,
				id: userInfo?.id,
				email: userInfo?.email,
				Phone: `${userInfo?.country_code}${userInfo?.cellphone}`,
				browser: getBrowser(),
			});

			if (userInfo?.id && window.clarity) {
				window.clarity('identify', userInfo.id.toString());
			}

			getAllProducts();
		} catch (error) {}
	};

	useEffect(() => {
		storage.setItem(SESSION_STORAGE_KEY.GO_TO, location.pathname);

		// PLATFORM OS
		const platformOS = searchParams.get(URL_PARAMS.PLATFORM_OS);
		if (platformOS) {
			storage.setItem(STORAGE_KEYS.PLATFORM_OS, platformOS.toString());
		}

		// DEEPLINK CHECKOUT
		const checkout = searchParams.get(URL_PARAMS.CHECKOUT);
		if (checkout && checkout === 'open') {
			dispatch(setShowModalCheckout(true));
			handleNavigateWithParams(ROUTES.PLANS_AND_PRICES);
		}

		// DEEPLINK PLANS REGULARIZATIONS
		const flow = searchParams.get(URL_PARAMS.FLOW);
		if (flow && flow === 'reg') {
			handleNavigateWithParams(ROUTES.PLANS_AND_PRICES);
			dispatch(setSelectedInvoiceCategory(PLAN_CATEGORY.REGULARIZATIONS));
		}

		// DEEPLINK PLANS ANNUAL
		if (flow && flow === 'annual') {
			handleNavigateWithParams(ROUTES.PLANS_AND_PRICES);
			dispatch(setSelectedInvoiceCategory(PLAN_CATEGORY.ANNUAL_DECLARATIONS));
		}
	}, [searchParams]);

	const handleNavigateWithParams = (path: string) => {
		setTimeout(() => {
			navigate({
				pathname: path,
				search: searchParams.toString(),
			});
		}, 0);
	};

	useEffect(() => {
		// UTMs
		const isSessionAvailable = isSessionStorageEnabled();
		const utmCampaign = isSessionAvailable
			? sessionStorage.getItem(LOCAL_STORAGE_KEY.CAMPAIGN)
			: utmInfo?.utm_campaign || null;
		const utmSource = isSessionAvailable
			? sessionStorage.getItem(LOCAL_STORAGE_KEY.SOURCE)
			: utmInfo?.utm_source || null;
		const utmMedium = isSessionAvailable
			? sessionStorage.getItem(LOCAL_STORAGE_KEY.MEDIUM)
			: utmInfo?.utm_medium || null;
		const checkoutParam =
			searchParams.get('checkout') ||
			(isSessionAvailable
				? sessionStorage.getItem(LOCAL_STORAGE_KEY.CHECKOUT)
				: null);
		const annualParam =
			searchParams.get('annual') ||
			(isSessionAvailable
				? sessionStorage.getItem(LOCAL_STORAGE_KEY.ANNUAL)
				: null);
		const productAdded =
			searchParams.get('product_added') ||
			(isSessionAvailable
				? sessionStorage.getItem(LOCAL_STORAGE_KEY.PRODUCT_ADDED)
				: null);
		const skipSamantha =
			searchParams.get('skip_samantha') ||
			(isSessionAvailable
				? sessionStorage.getItem(LOCAL_STORAGE_KEY.SKIP_SAMANTHA)
				: null);
		const flow =
			searchParams.get('flow') ||
			(isSessionAvailable
				? sessionStorage.getItem(LOCAL_STORAGE_KEY.FLOW)
				: null);
		const flowPeriodicity =
			searchParams.get('flow_periodicity') ||
			(isSessionAvailable
				? sessionStorage.getItem(LOCAL_STORAGE_KEY.FLOW_PERIODICITY)
				: null);

		if (utmCampaign) {
			searchParams.set(HERU_UTM.CAMPAIGN, utmCampaign);
		}
		if (utmSource) {
			searchParams.set(HERU_UTM.SOURCE, utmSource);
		}
		if (utmMedium) {
			searchParams.set(HERU_UTM.MEDIUM, utmMedium);
		}
		if (checkoutParam) {
			searchParams.set('checkout', checkoutParam);
			if (isSessionAvailable) {
				sessionStorage.setItem(LOCAL_STORAGE_KEY.CHECKOUT, checkoutParam);
			}
		}
		if (annualParam) {
			searchParams.set('annual', annualParam);
			if (isSessionAvailable) {
				sessionStorage.setItem(LOCAL_STORAGE_KEY.ANNUAL, annualParam);
			}
		}
		if (productAdded) {
			searchParams.set('product_added', productAdded);
			if (isSessionAvailable) {
				sessionStorage.setItem(LOCAL_STORAGE_KEY.PRODUCT_ADDED, productAdded);
			}
		}
		if (skipSamantha) {
			searchParams.set('skip_samantha', skipSamantha);
			if (isSessionAvailable) {
				sessionStorage.setItem(LOCAL_STORAGE_KEY.SKIP_SAMANTHA, skipSamantha);
			}
		}
		if (flow) {
			searchParams.set('flow', flow);
			if (isSessionAvailable) {
				sessionStorage.setItem(LOCAL_STORAGE_KEY.FLOW, flow);
			}
		}
		if (flowPeriodicity) {
			searchParams.set('flow_periodicity', flowPeriodicity);
			if (isSessionAvailable) {
				sessionStorage.setItem(
					LOCAL_STORAGE_KEY.FLOW_PERIODICITY,
					flowPeriodicity,
				);
			}
		}
		if (
			utmCampaign ||
			utmSource ||
			utmMedium ||
			annualParam ||
			checkoutParam ||
			productAdded ||
			skipSamantha
		) {
			navigate(`${location.pathname}?${searchParams.toString()}`);
		}

		// FRONT APP WIDGET
		if (userToken && !userProfile) getStorageProfile();
		if (location.pathname.includes(ROUTES.INTRO)) {
			const frontWidget = document.getElementById('front-chat-iframe');
			if (frontWidget) {
				frontWidget.remove();
			}
		}
	}, [location.pathname]);

	useEffect(() => {
		if (userProfile && userProfile?.id) {
			(async () => {
				await growthbookFile.loadFeatures();
				growthbookFile?.setAttributes({
					user_id: userProfile?.id,
				});
			})();
		}
	}, [userProfile?.id, tokenSession]);

	useEffect(() => {
		const utmCampaign = searchParams.get(HERU_UTM.CAMPAIGN);
		const utmSource = searchParams.get(HERU_UTM.SOURCE);
		const utmMedium = searchParams.get(HERU_UTM.MEDIUM);
		const annualParam = searchParams.get('annual');
		const checkoutParam = searchParams.get('checkout');
		const productAdded = searchParams.get('product_added');
		const flow = searchParams.get('flow');
		const flowPeriodicity = searchParams.get('flow_periodicity');

		if (utmCampaign && utmSource && utmMedium) {
			if (isSessionStorageEnabled()) {
				sessionStorage.setItem(LOCAL_STORAGE_KEY.OVERWRITE_UTM, 'false');
				sessionStorage.setItem(LOCAL_STORAGE_KEY.CAMPAIGN, utmCampaign);
				sessionStorage.setItem(LOCAL_STORAGE_KEY.SOURCE, utmSource);
				sessionStorage.setItem(LOCAL_STORAGE_KEY.MEDIUM, utmMedium);
			} else {
				dispatch(setOverwriteUtm(false));
				dispatch(
					updateUTMs({
						utm_campaign: utmCampaign,
						utm_medium: utmMedium,
						utm_source: utmSource,
					}),
				);
			}
		}

		if (isSessionStorageEnabled()) {
			if (annualParam) {
				sessionStorage.setItem(LOCAL_STORAGE_KEY.ANNUAL, annualParam);
			}
			if (checkoutParam) {
				sessionStorage.setItem(LOCAL_STORAGE_KEY.CHECKOUT, checkoutParam);
			}
			if (productAdded) {
				sessionStorage.setItem(LOCAL_STORAGE_KEY.PRODUCT_ADDED, productAdded);
			}
			if (flow) {
				sessionStorage.setItem(LOCAL_STORAGE_KEY.FLOW, flow);
			}
			if (flowPeriodicity) {
				sessionStorage.setItem(
					LOCAL_STORAGE_KEY.FLOW_PERIODICITY,
					flowPeriodicity,
				);
			}
		}

		// TOKEN
		const tokenByUrl = searchParams.get('token');
		if (tokenByUrl) {
			if (isLocalStorageEnabled()) {
				localStorage.setItem(LOCAL_STORAGE_KEY.TOKEN, tokenByUrl);
			} else {
				dispatch(addToken(tokenByUrl));
			}
			dispatch(login());
			getStorageProfile();
			return;
		} else {
			if (!userToken) {
				setTokenExist(false);
			}
		}

		if (!storage.getItem(STORAGE_KEYS.PLATFORM_OS)) {
			storage.setItem(STORAGE_KEYS.PLATFORM_OS, PlatformOS.WEB);
		}
	}, []);

	if (
		isLocalStorageEnabled() &&
		((!userToken && !isUser) || !userToken) &&
		!tokenExist
	) {
		return (
			<Navigate
				to={redirectPath}
				replace
			/>
		);
	}

	if (location.pathname.includes(ROUTES.REGISTER)) {
		return <Outlet />;
	}

	if (location.pathname.includes('onboarding')) {
		return <Outlet />;
	}

	return (
		<MainLayout>
			<Outlet />
		</MainLayout>
	);
};

export default React.memo(ProtectedRoutes);
