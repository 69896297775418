import { PAYMENT_METHOD, PLATFORM_CATALOG } from '../@types/Checkout.type';
import {
	PaymentIntent,
	ProcessPayment,
	ProcessV2Payment,
} from '../@types/Payments.types';
import HERU_API from '../shared/constants/heruApi';
import { post, get, del, put } from '../shared/utils/FetchSSL.util';

const getStripeClientSecret = async (
	tokenSession: string = '',
	id: number,
	userId: number,
	tokenStripe: string = '',
	tokenId: string = '',
	renewSubscription: boolean = false,
	additionalInformation: any = {},
	sourceChannel: string = '',
) => {
	try {
		const response = await post<ProcessPayment>(
			HERU_API.PROCESS_CLIENT_SECRET_PAYMENT(userId, id, renewSubscription),
			{
				payment_method: 'CARD',
				token_id: tokenId,
				platform_id: 2,
				token_stripe: tokenStripe,
				additional_information: additionalInformation,
				source_channel: sourceChannel,
			},
			{ token: true, isNewGateway: true, tokenSession },
		);
		return response as ProcessPayment;
	} catch (error) {
		throw error;
	}
};

const getCreateIntent = async (userId: number, tokenSession?: string) => {
	try {
		const response = await post<PaymentIntent>(
			HERU_API.CLIENT_INTENT,
			{
				userId,
				newFlowStripe: true,
			},
			{ token: true, isNewGateway: true, tokenSession },
		);
		return response as PaymentIntent;
	} catch (error) {
		throw error;
	}
};

const getClientCustomer = async (tokenSession?: string) => {
	try {
		const response = await get(HERU_API.GET_CUSTOMER, {
			token: true,
			isNewGateway: true,
			tokenSession,
		});
		return response;
	} catch (error) {
		throw error;
	}
};

const createNewCustomer = async (tokenSession?: string) => {
	try {
		const response = await post(
			HERU_API.CREATE_CUSTOMER,
			{
				platform_id: 2,
			},
			{ token: true, isNewGateway: true, tokenSession },
		);

		return response;
	} catch (error) {
		throw error;
	}
};

const getPaymentCards = async (tokenSession?: string) => {
	try {
		const data = await get(HERU_API.HERU_CORE_PAYMENTS_LIST_CARDS, {
			token: true,
			isNewGateway: true,
			tokenSession,
		});
		return data;
	} catch (error) {
		throw error;
	}
};

const deletePaymentCard = async (tokenId: string, tokenSession?: string) => {
	try {
		const response = await del(
			HERU_API.HERU_CORE_PAYMENTS_DELETE_CARD(tokenId),
			{
				token: true,
				isNewGateway: true,
				tokenSession,
			},
		);
		return response;
	} catch (error) {
		throw error;
	}
};

const postDefaultCard = async (pmId: string, tokenSession?: string) => {
	try {
		const response = await put(
			HERU_API.HERU_CORE_PAYMENTS_DEFAULT_CARD(pmId),
			{},
			{
				token: true,
				isNewGateway: true,
				tokenSession,
			},
		);
		return response;
	} catch (error) {
		throw error;
	}
};

const sendStripeToken = async (
	customerId: string,
	paymentToken: string,
	tokenSession?: string,
) => {
	try {
		const response = await post(
			HERU_API.HERU_CORE_PAYMENTS_LIST_CARDS,
			{
				customer_id: customerId,
				platform_id: 2,
				platform_token_id: paymentToken,
			},
			{ token: true, isNewGateway: true, tokenSession },
		);

		return response;
	} catch (error) {
		throw error;
	}
};

type TShoppingCartProcess = {
	cartId: number;
	tokenId: string;
	renewSubscription: boolean;
	additionalInformation: Record<string, any>;
	sourceChannel: string;
	paymentMethod: PAYMENT_METHOD;
	platformId: PLATFORM_CATALOG;
	tokenSession?: string;
	installments?: number;
};
type TShoppingCartPrepareProcessCreate = {
	shoppingCartId: number;
	tokenSession?: string;
	paymentMethod: PAYMENT_METHOD;
	platformId: PLATFORM_CATALOG;
};
type TShoppingCartPrepareProcessUpdate = {
	shoppingCartId: number;
	tokenSession?: string;
	tokenId: string;
	paymentMethod: PAYMENT_METHOD;
	platformId: PLATFORM_CATALOG;
};
const postShoppingCartProcess = async ({
	tokenSession,
	cartId,
	tokenId,
	// renewSubscription = false,
	additionalInformation,
	sourceChannel,
	paymentMethod,
	platformId,
	installments,
}: TShoppingCartProcess) => {
	try {
		const response = await post<ProcessV2Payment>(
			HERU_API.HERU_CORE_PAYMENTS_SHOPPING_PROCESSV2(cartId),
			{
				token_id: tokenId,
				platform_id: platformId,
				payment_method: paymentMethod,
				source_channel: sourceChannel,
				additional_information: additionalInformation,
				...(installments && { installments }),
			},
			{ token: true, isNewGateway: true, tokenSession },
		);
		return response as ProcessV2Payment;
	} catch (error) {
		throw error;
	}
};

const shoppingCartPrepareProcessCreate = async ({
	tokenSession,
	shoppingCartId,
	paymentMethod,
	platformId
}: TShoppingCartPrepareProcessCreate) => {
	try {
		const response = await post<ProcessV2Payment>(
			HERU_API.HERU_CORE_PAYMENTS_SHOPPING_PREPARE_PROCESSV2(shoppingCartId),
			{
				payment_method: paymentMethod,
				platform_id: platformId,
			},
			{ token: true, isNewGateway: true, tokenSession },
		);
		return response as ProcessV2Payment;
	} catch (error) {
		throw error;
	}
};

const shoppingCartPrepareProcessUpdate = async ({
	tokenSession,
	shoppingCartId,
	tokenId,
	paymentMethod,
	platformId
}: TShoppingCartPrepareProcessUpdate) => {
	try {
		const response = await put(
			HERU_API.HERU_CORE_PAYMENTS_SHOPPING_PREPARE_PROCESSV2(shoppingCartId),
			{
				payment_method: paymentMethod,
				platform_id: platformId,
				token_id: tokenId,
			},
			{ token: true, isNewGateway: true, tokenSession },
		);
		return response.data.resource as ProcessV2Payment;
	} catch (error) {
		throw error;
	}
};


export {
	getCreateIntent,
	createNewCustomer,
	getClientCustomer,
	getStripeClientSecret,
	getPaymentCards,
	sendStripeToken,
	deletePaymentCard,
	postDefaultCard,
	postShoppingCartProcess,
	shoppingCartPrepareProcessCreate,
	shoppingCartPrepareProcessUpdate
};
