import HERU_API from '../shared/constants/heruApi';
import { get, patch, post } from '../shared/utils/FetchSSL.util';

const getUserProfile = async (tokenSession?: string) => {
	try {
		const response = await get(HERU_API.USER_PROFILE, {
			token: true,
			isNewGateway: true,
			tokenSession,
		});
		return response;
	} catch (error) {
		throw error;
	}
};

const patchUserProfile = async (
	userId: number,
	body: {
		first_name?: string;
		father_surname?: string;
		email?: string;
		utm_campaign?: string;
		utm_medium?: string;
		utm_source?: string;
	},
	tokenSession?: string,
) => {
	try {
		const response = await patch(
			`${HERU_API.NEW_UPDATE_USER_PROFILE}/${userId}`,
			body,
			{
				token: true,
				isNewGateway: true,
				tokenSession,
			},
		);
		return response;
	} catch (error) {
		throw error;
	}
};

const fetchTermsAndConditions = async () => {
	try {
		const response = await get(HERU_API.COMPLIANCE_DOCUMENT_TC, {
			isNewGateway: true,
		});
		return response;
	} catch (error) {
		throw error;
	}
};

const acceptCompliance = async (doc_type: 'T&Cs', tokenSession?: string) => {
	try {
		const response = await post(
			HERU_API.COMPLIANCE_LOG,
			{ doc_type },
			{
				isNewGateway: true,
				tokenSession,
				token: true,
			},
		);
		return response;
	} catch (error) {
		throw error;
	}
};

const fetchProfileFiscalRetentions = async (
	userId: number,
	tokenSession?: string,
) => {
	try {
		const response = await get(
			`${HERU_API.PROFILE_FISCAL_RETENTIONS}/${userId}`,
			{
				token: true,
				isNewGateway: true,
				tokenSession,
			},
		);
		return response;
	} catch (error) {
		throw error;
	}
};

const deleteAccount = async (tokenSession?: string) => {
	try {
		const response = await post(
			HERU_API.HERU_API_DESACTIVE_ACCOUNT,
			{},
			{
				token: true,
				isNewGateway: true,
				tokenSession,
			},
		);
		return response;
	} catch (error) {
		throw error;
	}
};

const attributeUser = async (body: {
	user_id: number;
	channel: string;
	channel_drilldown1?: string;
	channel_drilldown2?: string;
	channel_drilldown3?: string;
	channel_drilldown4?: string;
	landing_page_group?: string;
	landing_page?: string;
}, tokenSession?: string) => {
	try {
		const response = await post(
			HERU_API.ATTRIBUTE_USER,
			body,
			{
				token: true,
				isNewGateway: true,
				tokenSession,
			},
		);
		return response;
	} catch (error) {
		throw error;
	}
};

export {
	getUserProfile,
	patchUserProfile,
	fetchTermsAndConditions,
	acceptCompliance,
	fetchProfileFiscalRetentions,
	deleteAccount,
	attributeUser,
};
