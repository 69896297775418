import React, { useEffect } from 'react';
import { CircularProgress } from '@mui/material';
import { useSelector } from 'react-redux';
import { SATLinkStatus } from '../../@types/SATLinking';
import UserCredentialsLinked from '../../components/organisms/UserCredentialsLinked';
import growthbookFile from '../../shared/constants/growthbook';
import { ANALYTICS_EVENTS } from '../../shared/constants/events';
import { RootState } from '../../redux/store';
import useCleverTapEvent from '../../hooks/useClevertapEvent';
import { useFeatureIsOn } from '@growthbook/growthbook-react';
import GROWTHBOOK_FEATURE_FLAGS from '../../shared/utils/growthbook.util';

import UnlinkedUser from './organisms/UnlinkedUser';
import SatAlert from './molecules/SatAlert';
import useService from '../../hooks/useService';
import { PRODUCTS_SLUG } from '../../shared/constants/SlugsProducts';
import UserZero from './organisms/UserZero';

const HomePage: React.FC = () => {
	const logClevertapEvent = useCleverTapEvent();
	const {
		getSatLinkedAccount,
		getAcquisitions,
		getOnboardingIAProgress,
		getSubscriptions,
		isLoading,
	} = useService();

	const showErrorSatAlert = useFeatureIsOn(
		GROWTHBOOK_FEATURE_FLAGS.SHOW_ERROR_SAT_ALERT,
	);

	const { status, isLoading: isLoadingSatStatus } = useSelector(
		(state: RootState) => state.satLinkStatus,
	);
	const { user: userProfile, activeSuscriptions } = useSelector(
		(state: RootState) => state.profile,
	);
	const { acquisitions } = useSelector((state: RootState) => state.productList);

	const acquisitionsWithoutAsat = acquisitions.filter(
		(acquisition) =>
			acquisition?.product?.slug !== PRODUCTS_SLUG.ASSISTANCE_ASAT,
	);

	const userIsLinked = status.status === SATLinkStatus.ACTIVE;
	const userHavePurchases =
		acquisitionsWithoutAsat.length > 0 || activeSuscriptions.length > 0;

	const showUnlinkedUser = userHavePurchases && !userIsLinked;
	const showUserZero = !userHavePurchases && !userIsLinked;

	const isLoadingPage =
		isLoadingSatStatus ||
		isLoading.adquisitions ||
		isLoading.onboardingIAProgress ||
		isLoading.serviceStatus;

	const loadGrowthbook = async () => {
		await growthbookFile.loadFeatures();
	};

	useEffect(() => {
		if (userProfile?.id) {
			loadGrowthbook();
			growthbookFile?.setAttributes({
				user_id: userProfile?.id,
			});
			logClevertapEvent(ANALYTICS_EVENTS.PAGE_VIEWED, {
				name: '/home',
			});
			if (window.clarity) {
				window.clarity('identify', userProfile.id.toString());
			}

			getOnboardingIAProgress(userProfile.id);
			getSatLinkedAccount(userProfile.id, userProfile.cellphone);
		}
	}, [userProfile?.id]);

	useEffect(() => {
		getAcquisitions();
		getSubscriptions();
	}, []);

	return (
		<section className='p-4 md:px-10 md:py-5 bg-h-neutral-94'>
			{isLoadingPage ? (
				<div className='flex items-center justify-center mt-5'>
					<CircularProgress size={25} />
				</div>
			) : (
				<>
					{showErrorSatAlert && <SatAlert />}
					{showUserZero ? (
						<UserZero />
					) : showUnlinkedUser ? (
						<UnlinkedUser page='home' />
					) : (
						<UserCredentialsLinked />
					)}
				</>
			)}
		</section>
	);
};

export default React.memo(HomePage);
