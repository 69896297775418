import { useMemo } from 'react';
import Text from '../../atoms/Text';
import { PAYMENT_METHOD } from '../../../@types/Checkout.type';
import PaymentMethodItem from '../../../pages/checkout/components/molecules/PaymentMethodItem';
import OxxoPay from '../../../assets/img/svg/oxxopay.svg';
import Spei from '../../../assets/img/svg/spei.svg';
import Paypal from '../../../assets/img/svg/logo-Paypal.svg';
import { getOrderRefresh } from '../../../services/orders.service';
import { useSelector } from 'react-redux';
import { RootState } from '../../../redux/store';

const paymentsOptions = [
	PAYMENT_METHOD.CASH,
	PAYMENT_METHOD.SPEI,
	PAYMENT_METHOD.PAYPAL,
];

interface PaymentMethodsProps {
	category: PAYMENT_METHOD;
	setCategory: (category: PAYMENT_METHOD) => void;
}

const PaymentMethods = ({ category, setCategory }: PaymentMethodsProps) => {
	const { tokenSession } = useSelector((state: RootState) => state.auth);

	const categoryName = useMemo(
		() => (_category: PAYMENT_METHOD) => {
			switch (_category) {
				case PAYMENT_METHOD.CASH:
					return 'Efectivo';
				case PAYMENT_METHOD.SPEI:
					return 'Transferencia';
				case PAYMENT_METHOD.PAYPAL:
					return 'PayPal';
				default:
					return 'N/A';
			}
		},
		[],
	);

	const categoryImage = useMemo(
		() => (_category: PAYMENT_METHOD) => {
			switch (_category) {
				case PAYMENT_METHOD.CARD:
					return '';
				case PAYMENT_METHOD.CASH:
					return OxxoPay;
				case PAYMENT_METHOD.SPEI:
					return Spei;
				case PAYMENT_METHOD.PAYPAL:
					return Paypal;
				default:
					return '';
			}
		},
		[],
	);

	const categoryDescription = useMemo(
		() => (_category: PAYMENT_METHOD) => {
			switch (_category) {
				case PAYMENT_METHOD.CARD:
					return 'Paga fácil con cualquier tarjeta bancaria';
				case PAYMENT_METHOD.CASH:
					return 'Completa tu pago en cualquier sucursal';
				case PAYMENT_METHOD.SPEI:
					return 'Completa tu pago en la app de tu banco';
				case PAYMENT_METHOD.PAYPAL:
					return 'Paga con tu cuenta de PayPal';
				default:
					return '';
			}
		},
		[],
	);

	const getUserOrder = async (paymentMethod: PAYMENT_METHOD) => {
		try {
			const params = `?status=open&status=failed&is_renewal=false&payment_method=${paymentMethod}`;
			const orders = await getOrderRefresh(params, tokenSession);

			return orders;
		} catch (error) {
			return error;
		}
	};

	const onChangePaymentMethod = (paymentMethod: PAYMENT_METHOD) => {
		setCategory(paymentMethod);
		getUserOrder(paymentMethod);
	};

	return (
		<div>
			<Text
				size='body-3'
				sx='mb-1 mt-2'
				color='darkMenu'
			>
				Otros métodos de pago
			</Text>

			<div className='flex flex-col gap-y-2 mt-4'>
				{paymentsOptions.map((item) => (
					<button
						key={item}
						onClick={() => onChangePaymentMethod(item)}
						className={`w-full border-2 border-h-neutral-90 rounded-lg  ${
							category === item ? 'border-h-primary' : 'border-h-neutral-90'
						}`}
					>
						<PaymentMethodItem
							description={categoryDescription(item)}
							label={categoryName(item)}
							imgAlt='Logo del metodo de pago'
							imgSrc={categoryImage(item)}
							showDescription={category === item}
						/>
					</button>
				))}
			</div>
		</div>
	);
};

export default PaymentMethods;
